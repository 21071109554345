.tab-group {
	display: flex;
	border-bottom: 2px solid #eee;
}

.tab-element {
	padding:10px 20px;
	cursor: pointer;
	border: none;
	background:none;
	outline:none;
	font-size: 1.35em;
}

.tab-element-encours {
	font-weight:bold;
	background-color: #4ed515;
	color: white;
	-webkit-transition: all 0.65s ease-in-out;
    -o-transition: all 0.65s ease-in-out;
    transition: all 0.65s ease-in-out;
}

.tab-contenu {
	height: 800px;
}
