.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#page-loading, #page-madi-accueil {height: 100vh;}

.accordion-i {
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.accordion-titre {
  padding: 10px;
  background: #f5f5f5;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-label {
  font-size: 16px;
  transition: transform 0.3s;
}

.accordion-label.open {
  transform: rotate(45deg);
}

.accordion-div {
  padding: 10px;
  background: #fff;
}

